/*eslint-disable */
// import GPathCareerUnivScholarshipModel from '../../Model/GPathCareerUnivScholarship'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let GPathCareerUnivScholarships = {
  /**
   * gpathCareerUnivScholarshipList
   */
  async gpathCareerUnivScholarshipList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_career_univ_scholarship_list_console", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpathCareerUnivScholarshipList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * gpathCareerUnivScholarshipList
   */
    async gpath_career_univ_scholarship_list_console (context, whereFilter=null) {
      try{
        Utility.showLoader = true;
        Utility.loadingMsg = "Please wait....";
  
        let post_data = new FormData();  
        if (whereFilter) {
          post_data.append('filter', JSON.stringify(whereFilter))
        }
  
        return await request.curl(context, "gpath_career_univ_scholarship_list_console", post_data)
          .then(async response => {
            return response
          });
  
      }
      catch(err){
        console.error('Exception occurred at gpathCareerUnivScholarshipList() and Exception:',err.message)
      }
      finally{
        Utility.showLoader = false;
      }
    },
  /**
   * gpath_career_univ_scholarshipView
   */
  async gpath_career_univ_scholarshipView (context, gpathCareerUnivScholarshipId) {
    try {      
      let post_data = new FormData();
      post_data.append('gcus_id', gpathCareerUnivScholarshipId);
      return await request.curl(context, "gpath_career_univ_scholarship_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_career_univ_scholarshipView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_career_univ_scholarshipAdd
   */
  async gpath_career_univ_scholarshipAdd (context, gpath_career_univ_scholarshipObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_univ_scholarshipObj) {
        post_data.append(key, gpath_career_univ_scholarshipObj[key]);
      }

      return await request.curl(context, "gpath_career_univ_scholarship_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_univ_scholarshipAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_univ_scholarshipEdit
   */
  async gpath_career_univ_scholarshipEdit (context, gpath_career_univ_scholarshipObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_univ_scholarshipObj) {
        post_data.append(key, gpath_career_univ_scholarshipObj[key]);
      }

      return await request.curl(context, "gpath_career_univ_scholarship_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_univ_scholarshipEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_univ_scholarshipDelete
   */
  async gpath_career_univ_scholarshipDelete (context, gpathCareerUnivScholarshipId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gcus_id', gpathCareerUnivScholarshipId);

      return await request.curl(context, "gpath_career_univ_scholarship_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_career_univ_scholarshipDelete() and Exception:',err.message)
    }
  },

  /**
    * gpath_career_univ_country_list
    */
  async gpath_career_univ_scholarship_country_list (context) {
    try{
      let post_data = new FormData();
      return await request.curl(context, "gpath_career_univ_scholarship_country_list", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_career_univ_scholarship_country_list() and Exception:',err.message)
    }
  },
}
export {
  GPathCareerUnivScholarships
}
